/*------------------------------------*\
    #COLORS
\*------------------------------------*/

.color-heading {
  color: $color-heading !important
}

.color-theme {
  color: $color-theme !important
}

.color-white {
  color: $color-white !important
}

.color-gray {
  color: $color-gray !important
}
