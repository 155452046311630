/*------------------------------------*\
    #BACKGROUNDS
\*------------------------------------*/
.bg-shape {
	background-color: #fafaff;
	padding-bottom: 200px;
	padding-top: 250px;
	position: relative;

	// &:before,
	// &:after {
	// 	position: absolute;
	// 	content: "";
	// 	background: $color-white;
	// 	width: 200%;
	// 	height: 196px;

	// }

	// &:before {
	// 	top: -120px;
	// 	left: -20px;
	// 	@include prefix(transform, rotate(-5deg), moz webkit ms o);
	// }

	// &:after {
	// 	bottom: -38px;
	// 	left: 0;
	// 	@include prefix(transform, rotate(-5deg), moz webkit ms o);
	// }

	&:before,
	&:after {
		position: absolute;
		left: -25%;
		content: "";
		background: $color-white;
		width: 150%;
		height: 150px;
		@include prefix(transform, rotate(-3deg), moz webkit ms o);
		@include prefix(transform-origin, 3% 0, moz webkit ms o);
	}

	&:before {
		top: 0
	}

	&:after {
		bottom: -150px;
	}




}

/* Custom, iPhone Retina */
@media only screen and (max-width : 991px) {
	.bg-shape {
		padding-bottom: 200px;
		padding-top: 250px;
	}
}

.bg-theme {
	background-color: $color-theme !important
}

.bg-gray {
	background-color: $color-gray !important;
}

.bg-white {
	background-color: $color-white !important
}

.bg-dark {
	background-color: $color-heading !important
}

.bg-gradient {
	background-image: linear-gradient(268deg, #a87efb 0%, #7160ff 100%);
}

/* Background Image */
.bg-section {
	position: relative;
	overflow: hidden;
	z-index: 1;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-ms-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	width: 100%;
	height: 100%;
}

.bg-overlay:before {
	content: "";
	display: inline-block;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: -1;
}

.bg-overlay-light:before {
	background-color: $color-white;
	opacity: 0.8;
}

.bg-overlay-dark:before {
	background-color: $color-heading;
	opacity: 0.3;
}

.bg-overlay-theme:before {
	background-color: $color-theme;
	opacity: 0.7;
}

.bg-parallex {
	background-attachment: fixed;
}