/*------------------------------------*\
    #Menu
\*------------------------------------*/
.header {
	.navbar-collapse {
		padding-right: 0;
		padding-left: 0;
	}

	.navbar {
		bottom: 0;
		padding: 0;
		border-radius: 0;
		min-height: 100px;
		border: none;
		z-index: 1030;

		&.affix {
			top: 0;
		}

		.logo {
			max-width: 100%;
			height: auto;
			line-height: 140px;
		}

	}
}

.navbar-nav {
	>li {
		margin-right: 50px;

		&:last-child {
			margin-right: 0;
		}

		>a {
			font-size: 16px;
			text-transform: capitalize;
			font-weight: 400;
			line-height: 140px;
			color: $color-heading;
			padding: 0;

			&:hover {
				color: $color-theme;
			}
		}
	}
}