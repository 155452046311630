/*------------------------------------*\
    #HEADER
\*------------------------------------*/
.header {
	height: 140px;
}

.header-transparent {
	background-color: transparent;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}