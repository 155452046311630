/*-----------------------------------------------------------------------------------

	Theme Name: Siena
	Theme URI: http://themeforest.net/user/zytheme
	Description: Siena is a pixel perfect creative html5 marketing landing page  based on designed with great attention to details, flexibility and performance. It is ultra professional, smooth and sleek, with a clean modern layout.
	Author: zytheme
	Author URI: http://themeforest.net/user/zytheme
	Version: 1.0

-----------------------------------------------------------------------------------*/
/*!
// Contents
// ------------------------------------------------>

 1.  Global Styles
 2.  Typography
 3.	 Color
 4.	 Align
 5.	 Grid
 6.	 Background
 7.	 Buttons
 8. Forms
 9. Header
 10. Menu
 11. Hero
 12. Testimonial
 13. Feature
 14. Call To Action
 15. Pricing Tables
 16. Clients
 17. Footer

/*------------------------------------*\
    #GLOBAL STYLES
\*------------------------------------*/
/*
WARNING! DO NOT EDIT THIS FILE!

To make it easy to update your theme, you should not edit the styles in this file. Instead use 
the custom.css file to add your styles. You can copy a style from this file and paste it in 
custom.css and it will override the style in this file. You have been warned! :)
*/
body,
html {
	overflow-x: hidden;
}

html {
	font-size: $font-size-base;
}

body {
	background-color: $color-white;
	font-family: $font-body;
	font-size: $font-size-base;
	font-weight: 400;
	color: $color-body;
	line-height: $line-height-base;
	margin: 0;
}

.wrapper {
	background-color: $color-white;
}

* {
	outline: none;
}

::-moz-selection {
	text-shadow: none;
}

::selection {
	background-color: $color-theme;
	color: $color-white;
	text-shadow: none;
}

a {
	//.transition(0.3s, ease);
	color: $color-theme;
}

a:hover {
	color: darken($color-theme, 10%);
	text-decoration: none;
}

a:focus {
	color: darken($color-theme, 10%);
	outline: none;
	text-decoration: none;
}

a:active {
	color: darken($color-theme, 10%);
	outline: none;
	text-decoration: none;
}

textarea {
	resize: none
}

button.btn {
	margin-bottom: 0
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn.active.focus {
	outline: none
}

/*CUSTOM ABCOMM*/

.btn--assine{
	background: $color-theme;
	@include prefix(box-shadow, 0 3px 10px rgba(71, 44, 173, .20), moz webkit);
}

.hero{
	padding-top:240px !important;
	padding-bottom:0px !important;
}

#etapas{
	background: $color-theme;
	padding-top: 45px;
	padding-bottom: 45px;
	h2{
		color: $color-white !important;
	}
	p{
		color:$color-white !important;
	}
	.feature-panel{
		margin-bottom: 30px;
	}
	.bg-primary{
		background: $color-secondary !important;
		width:150px !important;
		height:150px !important;
		img{
			width:100px;
			padding-top:25px;
		}
	}
}

.footer-top{
	.footer-logo{
		float:left;
		img{
			width: 100px;
		}
	}
	.footer-info{
		float:left;
		margin-left:20px;
		padding-top:30px;
	}
}

#navbar-spy{
	background:$color-white !important;
	position: fixed !important;
	z-index: 999;
}

.text-form{
	color: #a0a0ba;
	font-family: "Roboto", sans-serif;
	font-size: 18px;
	font-weight: 400;
	line-height: 30px;
}

#form div[data-role="page"], #form[data-submission-phase="show-html"] > [data-submission-phase="show-html"], #form #editor-settings-panel{
	box-shadow: none;
}

#clients{
	h2{
		width: 100%;
		text-align: center;
		margin:50px auto;
		text-transform: uppercase;
		color: $color-theme;
		font-size: 2rem;
	}
	img{
		max-width: 100%;
	}
}