/*------------------------------------*\
    #Heading
\*------------------------------------*/

.heading {
  .heading-title {
    font-family: $font-heading;
    font-size: 32px;
    font-weight: 500;
    line-height: 47px;
    margin-bottom: 0;
  }

  .heading-desc {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 0;
    padding: 0 30px;
  }
}

@media only screen and (max-width : 991px) {

  .heading {
    margin-bottom: 30px;

    .heading-title {
      font-size: 30px;
    }
  }

}