/*------------------------------------*\
    #Call To Action
\*------------------------------------*/
.cta {
	h3 {
		font-size: 50px;
		font-weight: 500;
		line-height: 65px;
		margin-bottom: 23px;
		text-transform: capitalize;
	}

	p {
		font-size: 20px;
		font-weight: 400;
		line-height: 28px;
		margin-bottom: 48px;
	}
}

.cta .btn {
	height: 70px;
	width: 280px;
	font-size: 24px;
	line-height: 70px;
}