/*------------------------------------*\
    #Client
\*------------------------------------*/

.clients {
  padding-top: 100px;
  padding-bottom: 100px;

  .client {
    img {
      max-width: 100%;
      width: auto !important;
      margin-right: auto;
      margin-left: auto;
      text-align: center;
    }
  }
}

.clients-carousel [class*="col-"] {
  max-height: 80px;
}

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) and (max-width : 767px) {
  .client img {
    margin-bottom: 20px;
  }
}