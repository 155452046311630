/*------------------------------------*\
    #Align
\*------------------------------------*/
address,
blockquote,
dd,
dl,
fieldset,
form,
ol,
p,
pre,
table,
ul {
  margin-bottom: $baseline;
}

section {
  padding-top: 100px;
  padding-bottom: 100px;
  overflow: hidden;
}

/* Custom, iPhone Retina */
@media only screen and (min-width : 991px) {

  /* All margin */
  .m-0 {
    margin: 0 !important
  }

  .m-xs {
    margin: ($baseline/2);
  }

  .m-sm {
    margin: $baseline;
  }

  .m-md {
    margin: $baseline*2;
  }

  .m-lg {
    margin: $baseline*4;
  }

  /* top margin */
  .mt-0 {
    margin-top: 0;
  }

  .mt-xs {
    margin-top: ($baseline/2);
  }

  .mt-10 {
    margin-top: 10px !important;
  }

  .mt-20 {
    margin-top: 20px !important;
  }

  .mt-30 {
    margin-top: 30px !important;
  }

  .mt-40 {
    margin-top: 40px !important;
  }

  .mt-50 {
    margin-top: 50px !important;
  }

  .mt-60 {
    margin-top: 60px;
  }

  .mt-70 {
    margin-top: 70px !important;
  }

  .mt-80 {
    margin-top: 80px !important;
  }

  .mt-90 {
    margin-top: 90px !important;
  }

  .mt-100 {
    margin-top: 100px !important;
  }

  .mt-150 {
    margin-top: 150px;
  }

  /* bottom margin */
  .mb-0 {
    margin-bottom: 0 !important;
  }

  .mb-15 {
    margin-bottom: 15px;
  }

  .mb-10 {
    margin-bottom: 10px !important;
  }

  .mb-20 {
    margin-bottom: 20px !important;
  }

  .mb-30 {
    margin-bottom: 30px !important;
  }

  .mb-50 {
    margin-bottom: 50px !important;
  }

  .mb-40 {
    margin-bottom: 40px !important;
  }

  .mb-60 {
    margin-bottom: 60px !important;
  }

  .mb-70 {
    margin-bottom: 70px !important;
  }

  .mb-80 {
    margin-bottom: 80px !important;
  }

  .mb-90 {
    margin-bottom: 90px !important;
  }

  .mb-100 {
    margin-bottom: 100px !important;
  }

  .mb-150 {
    margin-bottom: 150px !important;
  }

  /* right margin */
  .mr-0 {
    margin-right: 0;
  }

  .mr-30 {
    margin-right: 30px !important;
  }

  .mr-50 {
    margin-right: 50px;
  }

  .mr-60 {
    margin-right: 60px;
  }

  .mr-150 {
    margin-right: 150px;
  }

  /* left margin */
  .ml-0 {
    margin-left: 0;
  }

  .ml-xs {
    margin-left: ($baseline/2);
  }

  .ml-sm {
    margin-left: $baseline;
  }

  .ml-md {
    margin-left: $baseline*2;
  }

  .ml-lg {
    margin-left: $baseline*4;
  }

  .ml-30 {
    margin-left: 30px !important;
  }

  .ml-50 {
    margin-left: 50px;
  }

  .ml-60 {
    margin-left: 60px;
  }

  .ml-150 {
    margin-left: 150px;
  }

  /* All padding */
  .p-0 {
    padding: 0 !important;
  }

  .p-xs {
    padding: ($baseline/2);
  }

  .p-sm {
    padding: $baseline;
  }

  .p-md {
    padding: $baseline*2;
  }

  .p-lg {
    padding: $baseline*4;
  }

  /* top padding */
  .pt-0 {
    padding-top: 0 !important;
  }

  .pt-20 {
    padding-top: 20px !important;
  }

  .pt-30 {
    padding-top: 30px !important;
  }

  .pt-40 {
    padding-top: 40px !important;
  }

  .pt-50 {
    padding-top: 50px;
  }

  .pt-60 {
    padding-top: 60px;
  }

  .pt-70 {
    padding-top: 70px !important;
  }

  .pt-80 {
    padding-top: 80px;
  }

  .pt-90 {
    padding-top: 90px;
  }

  .pt-100 {
    padding-top: 100px !important;
  }

  .pt-150 {
    padding-top: 150px !important;
  }

  /* bottom padding */
  .pb-0 {
    padding-bottom: 0 !important;
  }

  .pb-30 {
    padding-bottom: 30px;
  }

  .pb-50 {
    padding-bottom: 50px;
  }

  .pb-60 {
    padding-bottom: 60px;
  }

  .pb-70 {
    padding-bottom: 70px !important;
  }

  .pb-80 {
    padding-bottom: 80px;
  }

  .pb-90 {
    padding-bottom: 90px;
  }

  .pb-100 {
    padding-bottom: 100px !important;
  }

  /* right padding */
  .pr-0 {
    padding-right: 0;
  }

  .pr-xs {
    padding-right: ($baseline/2);
  }

  .pr-sm {
    padding-right: $baseline;
  }

  .pr-md {
    padding-right: $baseline*2;
  }

  .pr-lg {
    padding-right: $baseline*4;
  }

  .pr-15 {
    padding-right: 15px !important;
  }

  .pr-30 {
    padding-right: 30px !important;
  }

  .pr-50 {
    padding-right: 50px;
  }

  .pr-60 {
    padding-right: 60px;
  }

  .pr-100 {
    padding-right: 100px !important;
  }

  .pr-150 {
    padding-right: 150px;
  }

  /* left padding */
  .pl-0 {
    padding-left: 0 !important;
  }

  .pl-30 {
    padding-left: 30px;
  }

  .pl-50 {
    padding-left: 50px;
  }

  .pl-60 {
    padding-left: 60px;
  }

  .pl-100 {
    padding-left: 100px !important;
  }

  .pl-150 {
    padding-left: 150px;
  }
}

/* Postions */
.fixed {
  position: fixed;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.static {
  position: static;
}

/* Zindex*/
.zindex-1 {
  z-index: 1;
}

.zindex-2 {
  z-index: 2;
}

.zindex-3 {
  z-index: 3;
}

/* Borders */
.border-all {
  border: 1px solid $color-theme;
}

.border-top {
  border-top: 1px solid $color-theme;
}

.border-bottom {
  border-bottom: 1px solid $color-theme;
}

.border-right {
  border-right: 1px solid $color-theme;
}

.border-left {
  border-left: 1px solid $color-theme;
}

/* Display */
.inline {
  display: inline;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.hide {
  display: none;
}

.flex {
  display: flex;
}

@media only screen and (max-width : 767px) {
  section {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .text-center-xs {
    text-align: center !important;
  }

  .pull-none-xs {
    float: none !important;
    text-align: center !important;
  }

  .mb-15-xs {
    margin-bottom: 15px;
  }

  .mb-30-xs {
    margin-bottom: 30px !important;
  }

  .mb-50-xs {
    margin-bottom: 50px;
  }

  .mb-60-xs {
    margin-bottom: 60px !important;
  }

  .p-none-xs {
    padding-right: 0;
    padding-left: 0;
  }
}

@media only screen and (min-width : 768px) and (max-width : 991px) {
  .text-center-sm {
    text-align: center !important;
  }

  .mb-0-sm {
    margin-bottom: 0;
  }

  .mb-15-sm {
    margin-bottom: 15px;
  }

  .mb-30-sm {
    margin-bottom: 30px !important;
  }

  .mb-50-sm {
    margin-bottom: 50px;
  }

  .mb-60-sm {
    margin-bottom: 60px;
  }

  .pb-15-sm {
    padding-bottom: 15px;
  }

  .pb-30-sm {
    padding-bottom: 30px;
  }

  .pb-50-sm {
    padding-bottom: 50px;
  }

  .pb-60-sm {
    padding-bottom: 60px;
  }

  .p-none-sm {
    padding-right: 0;
    padding-left: 0;
  }

  .pull-none-sm {
    float: none !important;
    text-align: center !important;
  }
}

.border-b {
  border-bottom: 1px solid $color-gray;
}