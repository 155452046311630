/*------------------------------------*\
    #Features
\*------------------------------------*/
.bg-yellow {
	box-shadow: 0 4px 10px rgba(248, 198, 82, 0.3);
	background-color: #f8c652;
}

.bg-purple {
	box-shadow: 0 4px 10px rgba(197, 0, 238, 0.3);
	background-color: #c500ee;
}

.bg-blue {
	box-shadow: 0 4px 10px rgba(92, 72, 224, 0.3);
	background-color: #5c48e0;
}

.bg-green {
	box-shadow: 0 4px 10px rgba(2, 230, 184, 0.3);
	background-color: #02e6b8;
}

.bg-orange {
	box-shadow: 0 4px 10px rgba(230, 126, 0, 0.3);
	background-color: #ff8c00;
}


.feature-panel {
	position: relative;

	.feature-icon {
		color: $color-theme;
		font-size: 55px;
		line-height: 1;
		margin-bottom: 35px;
	}

	.feature-img {
		width: 70px;
		height: 70px;
		line-height: 70px;
		@include rounded(50%);
		margin-bottom: 40px;
		text-align: center;
	}

	.feature-content {
		h3 {
			font-size: 20px;
			font-weight: 500;
			line-height: 1;
			margin-bottom: 19px;
		}

		p {
			font-size: 16px;
			font-weight: 400;
			line-height: 28px;
			margin-bottom: 0;
		}

		ul {
			padding: 0;
			margin: 24px 0 0 0;
			list-style: none;

			li {
				padding-left: 30px;
				font-size: 16px;
				font-weight: 400;
				line-height: 26px;
				position: relative;
				margin-bottom: 15px;

				&:before {
					position: absolute;
					left: 0;
					top: 0;
					content: "\f00c";
					font-family: $awesome;
					font-size: 16px;
					line-height: 26px;
					color: #f8c652;
				}
			}
		}
	}
}

.feature-center {
	text-align: center;

	.feature-img {
		margin-right: auto;
		margin-left: auto;
	}
}

.feature-2 {
	.feature-panel {
		position: relative;

		.feature-content {
			h3 {
				font-size: 32px;
				line-height: 42px;
				margin-bottom: 24px;
			}

			p {
				margin-bottom: 12px;
			}
		}
	}
}

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) and (max-width : 767px) {

	.feature-panel,
	.feature-left .feature-panel {
		margin-bottom: 30px;

		.feature-icon {
			margin-bottom: 10px;
		}

		.feature img {
			max-width: 100%;
			height: auto
		}

		.feature-content {

			ul {
				li {
					&:before {
						position: relative;
						padding-right: 10px;
					}
				}
			}

		}


	}

	.feature-2 {
		.feature-panel {

			.feature-content {
				h3 {
					font-size: 25px;
					line-height: 36px;
				}
			}
		}
	}


}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) and (max-width : 991px) {
	.feature-panel {
		margin-bottom: 20px;
	}

	.feature-2 i {
		font-size: 35px;
	}

	.feature-2 .divider--line {
		margin-top: 20px;
		margin-bottom: 15px;
	}

	.feature-2 h2 {
		font-size: 25px;
	}

	.feature-2 p {
		font-size: 15px;
		line-height: 20px;
	}
}