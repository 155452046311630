/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/
.btn {
	font-family: $font-body;
	color: $color-white;
	position: relative;
	z-index: 2;
	font-size: 13px;
	font-weight: 700;
	text-transform: uppercase;
	text-align: center;
	@include rounded(4px);
	padding: 0;
	width: 210px;
	height: 55px;
	line-height: 55px;
	letter-spacing: 0.65px;
}

.btn.active,
.btn:active {
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	box-shadow: none;
}

/* Button Primary */
.btn--primary {
	background-color: $color-theme;
	color: $color-white;
	border-color: $color-theme;
}

.btn--primary:active,
.btn--primary:focus,
.btn--primary:hover {
	background-color: darken($color-theme, 10%);
	border-color: darken($color-theme, 10%);
	color: $color-white;
}

/* Button Gradient */
.btn--gradient {
	background-color: $color-theme;
	background-image: linear-gradient(270deg, #885bfe 0%, #664ffa 100%);
	color: $color-white;
	border: none;
	@include transition(all 300ms linear);
	background-size: 200% 200%;

	@include prefix(box-shadow, 0 3px 10px rgba(71, 44, 173, .20), moz webkit);

	&:hover,
	&:focus,
	&:active {
		background-position: right center;
		color: $color-white;
	}
}

/* Button Secondary*/
.btn--secondary {
	background-color: $color-heading;
	color: $color-white;
	border: 2px solid $color-heading;
}

.btn--secondary:active,
.btn--secondary:focus,
.btn--secondary:hover {
	background-color: $color-theme;
	color: $color-white;
	border-color: $color-theme;
}

/* Button White */
.btn--white {
	background-color: $color-white;
	color: $color-heading;
	border: 1px solid $color-white;
	border-radius: 4px;
}

.btn--white:active,
.btn--white:focus,
.btn--white:hover {
	background-color: $color-theme;
	color: $color-white;
	border-color: $color-theme;
}


/* Button Block */
.btn--block {
	width: 100%;
}

.btn--auto {
	width: auto;
}

/* Button Rounded */
.btn--rounded {
	border-radius: 26px;
}

/* Button Bordered */

.btn--bordered {
	background-color: transparent;
}

.btn--bordered.btn--primary {
	color: $color-theme;
	border-color: $color-theme
}

.btn--bordered.btn--primary:active,
.btn--bordered.btn--primary:focus,
.btn--bordered.btn--primary:hover {
	color: $color-white;
	background-color: $color-theme;
	border-color: $color-theme
}

.btn--bordered.btn--secondary {
	color: #2c303d;
	border-color: #e0e0e0
}

.btn--bordered.btn--secondary:active,
.btn--bordered.btn--secondary:focus,
.btn--bordered.btn--secondary:hover {
	color: $color-white;
	background-color: $color-heading;
	border-color: $color-heading
}

.btn--bordered.btn--white {
	color: $color-white;
	border-color: $color-white
}

.btn--bordered.btn--white:active,
.btn--bordered.btn--white:focus,
.btn--bordered.btn--white:hover {
	color: $color-theme;
	background-color: $color-white;
	border-color: $color-white
}

.btn--white.btn--secondary {
	color: $color-heading;
	border-color: $color-white;
	background-color: $color-white;
}

.btn--white.btn--secondary:active,
.btn--white.btn--secondary:focus,
.btn--white.btn--secondary:hover {
	color: $color-white;
	background-color: $color-theme;
	border-color: $color-theme;
}

.bg-theme .btn--primary,
.bg-overlay-theme .btn--primary {
	background-color: $color-heading;
	color: $color-white;
	border: none;
}

.portfolio-filter li a.active-filter,
.portfolio-filter li a:hover {
	color: $color-theme
}

.navbar-toggle {
	border-color: $color-theme;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
	margin-top: 35px;
}